var isIE11 = false;
var isIE10OrLess = false;

if (navigator.appName === 'Microsoft Internet Explorer') {
  isIE10OrLess = true;
} else if (window.navigator.userAgent.match(/MSIE|Trident/) !== null) {
  isIE11 = true;
}

if (isIE11 || isIE10OrLess) {
  var ieMsg =
    '' +
    // Container
    '<div style="max-width: 1080px; height: 1000px;">' +
    // Top bar
    '<div style="background-color: #faf9f8; z-index: 1000; width: 100%">' +
    '<div style="padding-top: 20px; padding-bottom: 20px; padding-left: 50px">' +
    '<img height="35px" src="/static/images/logo.png" />' +
    '</div> ' +
    '</div>' +
    '<div style="width: 100%; display: flex">' +
    // Text
    '<div style="padding-left: 50px; width: 50%; font-family: Gotham Narrow A, Gotham Narrow B">' +
    '<h2 style="font: Gotham Narrow A, Gotham Narrow B">You are using Internet Explorer.</h2>' +
    '<p>' +
    'We do not support Internet Explorer and suggest that you download a modern browser. <br/><br/>' +
    'We suggest one of the following browsers:<br/><br/>' +
    '<a href="https://www.google.com/intl/da/chrome"> Google Chrome </a> <br/>' +
    '<a href="https://www.mozilla.org/da/firefox/new/"> Mozilla Firefox </a> <br/>' +
    '<a href="https://www.microsoft.com/da-dk/edge"> Microsoft Edge </a> <br/>' +
    '<a href="https://www.apple.com/safari/"> Apple Safari </a> <br/>' +
    '<p/><br/>' +
    '<h2>Vi kan se du bruger Internet Explorer.</h2>' +
    '<p>' +
    'Vi understøtter desværre ikke Internet Explorer og anbefaler at du henter en nyere browser <br/><br/>' +
    'Vi forslår en af følgende browsere:<br/><br/>' +
    '<a href="https://www.google.com/intl/da/chrome"> Google Chrome </a> <br/>' +
    '<a href="https://www.mozilla.org/da/firefox/new/"> Mozilla Firefox </a> <br/>' +
    '<a href="https://www.microsoft.com/da-dk/edge"> Microsoft Edge </a> <br/>' +
    '<a href="https://www.apple.com/safari/"> Apple Safari </a> <br/>' +
    '<p/>' +
    '</div>' +
    // Logo video
    '<div style="width: 50%; height: 50%; display: flex">' +
    '<video height="100%" width="100%" autoplay="autoplay" loop="loop">' +
    '<source src="https://videos.ctfassets.net/wa0op2bm22wm/19V5CshN1CBSneb5iOLrXX/718c770f69c98d74a4b8b74fe28b31c1/logo_hero_animation.mp4" type="video/mp4" />' +
    '</video>' +
    '</div>' +
    '</div>' +
    '</div>';

  if (isIE11) {
    document.addEventListener('DOMContentLoaded', () => {
      document.getElementById('root').innerHTML = ieMsg;
    });
  }
  if (isIE10OrLess) {
    document.attachEvent('onreadystatechange', () => {
      if (document.readyState === 'complete') {
        // eslint-disable-next-line no-restricted-properties, no-caller
        document.detachEvent('onreadystatechange', arguments.callee);
        document.getElementById('root').innerHTML = ieMsg;
      }
    });
  }
}
